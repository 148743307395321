@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

:root {
    --text: #fff;
    --primary: rgb(213, 171, 132);
}

.btn {
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;

}

.btn--primary{
    background-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 0;
    color: var(--text);
    font-weight: 820;
    font-family: 'Poppins', sans-serif;
}

.btn--md {
    padding: 8px 20px;
    font-size: 1em;
}

.btn--lg {
    padding: 12px 26px;
    font-size: 1.2em;
}

.btn--md:hover,
.btn--lg:hover {
    opacity: 0.75;
    transition: 0.3s ease-out;
}



@media only screen and (max-width: 960px) {
.btn--md {
    padding: 8px 20px;
    font-size: 1em;
}

.btn--lg {
    padding: 10px 26px;
    font-size: 1.1em;
}
}