body{zoom:75%}

.topparts{
    margin-top: 1em;
    padding-right: 3em;
    width:350px;
    background-color:"#ECEAE2";
}

.topparts h1{
    text-align:center;
    line-height: 1em;
    margin-bottom: -0.2em;
}

.topparts p{
    text-align:center;
    font-size: 1.1em;
}

.main {
    display: flex;
    flex-wrap: nowrap;
    align-content:space-between;
    justify-content: center;
    float:top;
    width:"100%";
    background:#ECEAE2
}

.topdiv h1{
    text-align:center;
    }
.servicearea h1{
    text-align:left;
    }


.picturepart{
    position: relative;
    background: url(../assets/img3.jpeg) no-repeat center ;
    background-size: cover;
    height: 300px;
    width: 100%;
    float:left;
    margin-bottom: 2em;
}

.picturepart p{
    position: inherit;
    color: #fff;
    font-weight: 700;
    top: 0.5em;
    font-size: 3em;
    text-align: center;
    line-height: 1em;
 }

 .textpart {
     padding-left: 15%;
     width: 70%;
 }

 .textpart p{
     font-size: 1.2em;
     justify-content: center;
     
 }

 .textpart h2{
    font-size: 1.8em;
 }


 

 @media only screen and (max-width: 960px) {
body{zoom:90%}

.textpart {
    padding-left: 8%;
    width: 85%;
}

.textpart p{
    font-size: 0.9em;
    justify-content: center;
}

.textpart h2{
    font-size: 1.2em;
}

.picturepart p{
    position: inherit;
    color: #fff;
    font-weight: 700;
    top: 0.5em;
    font-size: 2em;
    text-align: center;
    line-height: 1em;
 }

.topparts{
    margin-top: 1em;
    padding-right: 3em;
    width:300px;
    padding-left: 10%;
    background-color:"#ECEAE2";
}

.topparts h1{
    text-align:center;
    line-height: 1em;
    margin-bottom: -0.2em;
    font-size: 1.5em;
}
.topparts p{
    font-size: 1em;
}

 }
