.logo-image {
    
    display: inline-block !important;
    max-width:15em;
    width: auto;
    margin-left: 10%;
}

.logo-image img {
    width:100%;
}

.navbar-container {
    background-color: #fff;
    display: flex;
    height: 9em;
    justify-content:space-between;
    align-items: center;
}


.menu-icon {
    right: 10%;
    margin-top: 1em;
    margin-right: 5%;
    font-size: 2rem;
    background: none;
    z-index:9999;
    position: absolute;
}

.toogle{
    margin-top: -1em;
    margin-right: 15%;
    width:2em;
    height:2em;
    background: transparent;
    z-index:9999;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.toogle::before{
    content:'';
    position: absolute;
    width:55%;
    height: 0.1em;
    background:rgb(0, 0, 0);
    transform: translateY(-0.2em);
    transition: 0.4s;
}

.toogle::after{
    content:'';
    position: absolute;
    width:55%;
    height: 0.1em;
    background:#000;
    transform: translateY(0.2em);
    transition: 0.4s;
}

.toogle.active::before{
    transform: translateY(0px) rotate(45deg);
}

.toogle.active::after{
    transform: translateY(0px) rotate(-45deg);
}

.nav-menu {
    background-color: transparent;
    width: 35%;
    height: 100%;
    display: flex;
    position: fixed;
    right: -100%;
    z-index:99;
    transition:  all 0.6s ease;

}

.nav-menu.active {
    background-color: #fff;
    width:30%;
    height: 100%;
    display: flex;
    position: fixed;
    right:0%;
    z-index:99;
    transition:  all 0.6s ease;
}

.sidebar-item {
    display: flex;
    font-style: italic;
    justify-content: start;
    align-items: center;
    margin-top: 2vh;
    padding: 1em 0em 0.35em 0em;
    height: 2em;
}

.sidebar-items {
    margin-top: 30%;
    
}

.sidebar-item a{
    text-decoration: none;
    color: rgb(175, 179, 186);
    font-size: 1.7em;
    font-weight: 500;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.sidebar-item a:hover {
    color: rgb(30, 36, 37);
    display: flex;
    align-items: center;
    font-size: 1.7em;
    font-weight: 500;
    border-radius: 4px;
    transition: all 0.2s ease-out;
}


@media only screen and (max-width: 1040px) {

    .nav-menu {
        background-color: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        position: fixed;
        right: -100%;
        z-index:50;
        opacity: 1;
        transition:  all 0.6s ease;
    }
    
    .nav-menu.active {
        width:100%;
        height: 100%;
        display: flex;
        position: fixed;
        right:0%;
        z-index:50;
        transition:  all 0.6s ease;
    }

    .sidebar-items {
        margin-top: 10%;
        margin-left: 0%;
        
    }    
    .sidebar-item {
        display: flex;
        font-style: italic;
        justify-content: start;
        align-items: center;
        margin-top: 2vh;
        padding: 1.2em 0em 0.35em 0em;
        height: 2em;
    }

    .sidebar-item a{
        text-decoration: none;
        color: rgb(175, 179, 186);
        font-size: 2em;
        font-weight: 500;
        display: flex;
        align-items: center;
        border-radius: 4px;
    }
    
    .sidebar-item a:hover {
        color: rgb(30, 36, 37);
        display: flex;
        align-items: center;
        font-size: 2em;
        font-weight: 500;
        border-radius: 4px;
        transition: all 0.2s ease-out;
    }

        .toogle{
        margin-top: -1em;
        right: 10%;
        width:2em;
        height:2em;
        background: transparent;
        z-index:9999;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    
}

@media only screen and (max-width: 860px) {

    .logo-image {
    
        display: inline-block !important;
        max-width:7em;
        width: auto;
        margin-left: 7%;
    }

    .menu-icon {
        right: 10%;
        margin-top: 0.5em;
        margin-right: 0%;
        font-size: 1.6rem;
        background: none;
        z-index:9999;
        position: absolute;
    }

    .toogle{
        margin-top: -1.2em;
        margin-right: 15%;
        width:1.6em;
        height:1.6em;
        background: transparent;
        z-index:9999;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }



    .navbar-container {
        background-color: #fff;
        display: flex;
        height: 4em;
        justify-content:space-between;
        align-items: center;
    }
    
    .sidebar-items {
        max-height: 200px;
        margin-top: 20%;
        overflow: 'auto'
    }
}


/* Responsive for limited height for mobile phone rotated */

@media only screen and (max-height: 500px) {
    .sidebar-items {
        margin-top: 8%;
        overflow: 'auto'    
    }

    .sidebar-item {
        display: flex;
        font-style: italic;
        justify-content: start;
        align-items: center;
        margin-top: 2vh;
        padding: 0.5em 0em 0.35em 0em;
        height: 2em;
    }

    .sidebar-item a{
        text-decoration: none;
        color: rgb(175, 179, 186);
        font-size: 1.4em;
        font-weight: 500;
        display: flex;
        align-items: center;
        border-radius: 4px;
    }
    
    .sidebar-item a:hover {
        color: rgb(30, 36, 37);
        display: flex;
        align-items: center;
        font-size: 1.4em;
        font-weight: 500;
        border-radius: 4px;
        transition: all 0.2s ease-out;
    }

}

@media only screen and (max-height: 300px) {
    .sidebar-items {
        margin-top: 8%;
        overflow: 'auto'    
    }

    .sidebar-item {
        display: flex;
        font-style: italic;
        justify-content: start;
        align-items: center;
        margin-top: 0vh;
        padding: 0em 0em 0.35em 0em;
        height: 2em;
    }

    .sidebar-item a{
        text-decoration: none;
        color: rgb(175, 179, 186);
        font-size: 1em;
        font-weight: 500;
        display: flex;
        align-items: center;
        border-radius: 4px;
    }
    
    .sidebar-item a:hover {
        color: rgb(30, 36, 37);
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: 500;
        border-radius: 4px;
        transition: all 0.2s ease-out;
    }

}