.map-canvas {
    height: 500px;
    width: 100%;
    max-width: 100%;
    position: relative;
  }
  .placeDiv {
    position: absolute;
  }
  .map-container {
    position: relative;
  }
  .placecard__container {
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    max-width: 50%;
    width: 100%;
    height:  140px;
    background: white none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 2px 2px 2px 2px;
    font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
    margin: 10px;
    padding: 9px 4px 9px 11px;
    overflow: hidden;
  }
  .placecard__left {
    float: left;
    width: 80%;
  }
  .placecard__right {
    text-align: center;
    float: left;
    width: 20%;
  }
  .placecard__business-name {
    cursor:default;
    height: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 600px;
    perspective-origin: 100px 9.5px;
    transform-origin: 100px 9.5px;
    font: normal normal 700 normal 2.5em / normal 'Poppins', sans-serif;
    overflow: hidden;
    margin: 0;
  }
  .placecard__info {
    color: #5b5b5b;
    cursor: default;
    height: 32px;
    width: 200px;
    column-rule-color: #5b5b5b;
    perspective-origin: 100px 16px;
    transform-origin: 100px 16px;
    border: 0px none #5b5b5b;
    font: normal normal normal normal 1.5em / normal Roboto, Arial;
    margin: 0.8em 0px 0px;
    outline: #5b5b5b none 0px;
  }
  .placecard__direction-icon {
    background: rgba(0, 0, 0, 0) url("https://maps.gstatic.com/mapfiles/embed/images/entity11.png") repeat scroll 0px 0px / 70px 210px padding-box border-box;
    height: 24px;
    width: 24px;
    margin-right:15%;
    margin-left: auto;
  }
  .placecard__direction-link {
    color: #3a84df;
    display: block;
    height: 43px;
    font-size: 1.4em;
    text-decoration: none;
    width: 54.7344px;
  }
  .placecard__view-large {
    display: block;
    font-size: 1.5em;
    margin-top: 2.8em;
    color: #3a84df;
    text-decoration: none;
  }
  
  
@media only screen and (max-width: 1260px) {
  .placecard__left {
    float: left;
    width: 70%;
  }
  .placecard__right {
    text-align: center;
    float: left;
    width: 30%;
  }

  .placecard__container {
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    max-width: 35%;
    width: 100%;
    height:  130px;
    background: white none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 2px 2px 2px 2px;
    font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
    margin: 10px;
    padding: 9px 4px 9px 11px;
    overflow: hidden;
  }

  .placecard__business-name {
    cursor:default;
    height: 1.2em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 600px;
    perspective-origin: 100px 9.5px;
    transform-origin: 100px 9.5px;
    font: normal normal 700 normal 1.6em / normal 'Poppins', sans-serif;
    overflow: hidden;
    margin: 0;
  }

  .placecard__info {
    color: #5b5b5b;
    cursor: default;
    height: 32px;
    width: 200px;
    column-rule-color: #5b5b5b;
    perspective-origin: 100px 16px;
    transform-origin: 100px 16px;
    border: 0px none #5b5b5b;
    font: normal normal normal normal 1.3em / normal Roboto, Arial;
    margin: 1.2em 0px 0px;
    outline: #5b5b5b none 0px;
  }

}