.main{
    width:100%;
    display: flex;
    flex-wrap: wrap;
}

.field {
    margin-bottom: 0.5em;
}

.field label{
    font-size:1.3em;color:#2f5687;
    line-height: 2em;
    
}

.field input {
    display: block;
    width:'90%';
    font-size:1.2em;
    width:90%;
    height: 2em;
    border:1px solid #BBB3B9;
}

.field textarea{
    margin-bottom: 2em;
    display: block;
    font-size:1.5em;
    width:90%;
    border:'1px solid #BBB3B9';
}

.textItemsss {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-content:space-between;
}

.shownroom-text p{
    color:rgb(66, 64, 64);
    line-height: 1em;
    font-size: 1.3em;

}

.contact-text p{
    color:rgb(66, 64, 64);
    line-height: 0.8em;
    font-size: 1.3em;

}


.textItem1 {
    width: 40%;
    padding-right: 5em;
}

.textItem2 {
    width: 40%;
}

.textItem2 h1 {
    font-size: 2em;
}


@media only screen and (max-width: 1260px) {

    .textItem1 {
        width: 90%;
        margin-left: 5%;
        padding-right: 0em;
    }
    .textItem2 {
        width: 100%;
        margin-left: 7.5%;
    }

    .textItem1 h1{
        font-size: 1.5em;
    }

    .textItem1 h2{
        font-size: 1.2em;
    }

    .textItem2 h1{
        font-size: 1.5em;
    }

    .textItem2 p{
        font-size: 0.9em;
        line-height: 0.5em;
    }
    
    .shownroom-text p{
        color:rgb(66, 64, 64);
        line-height: 1em;
        font-size: 1em;
    
    }
    
    .contact-text p{
        color:rgb(66, 64, 64);
        line-height: 0.8em;
        font-size: 1em;
    }


    .--p p{
        font-size: 1em;
    }
}