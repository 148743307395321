body{zoom:80%}

.header1{
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../assets/img1.jpg) no-repeat center  ;
    background-size: cover;
    height: 25em;
    width: 100%;

    display: flex;              /* establish flex container */

    flex-direction: column;     /* stack flex items vertically */
    
    justify-content: center;    /* center items vertically, in this case */
    align-items: center;        /* center items horizontally, in this case */
}


.head-text1 p{
    position: inherit;
    color: #fff;
    font-weight: 700;
    margin-top: 0em ;
    margin-bottom: 0.5em;
    font-size: 4rem;
    text-align: center;
    line-height: 1em;
    font-family: 'Poppins';
 }


.header2{
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../assets/img2.jpeg) no-repeat center  ;
    background-size: cover;
    height: 14em;
    width: 100%;

    display: flex;              /* establish flex container */

    flex-direction: column;     /* stack flex items vertically */
    
    justify-content: center;    /* center items vertically, in this case */
    align-items: center;        /* center items horizontally, in this case */
}


 .head-text2 h1{
    position:inherit;
    color: #fff;
    font-weight: 500;
    margin-top: 0em;
    margin-bottom: 0.3em;
    font-size: 3.5rem;
    text-align: center;
 }

 .head-text2 p{ 
    color: #fff;
    margin-top: 0px;
    font-size: 1.3rem;

 }


 .main{
     width: 100%;
     flex-wrap:nowrap;
     align-content:space-between;
     justify-content: center;
     float:top;
     background:#ECEAE2
 }
 
.mainpart p{
    text-align:center;
    font-size: 1.1em;
}

.mainpart h1{
    text-align:center;
    line-height: 1em;
    font-size: 1.7em;
}

.mainpart{
    width:320px;
    padding:15px;
    background-color:"#ECEAE2";
    justify-items: center;
}





@media only screen and (max-width: 1040px) {
    body{zoom:90%}

    .main{
        width: 100%;

        display: flex;
        flex-wrap:wrap;
        align-content:space-between;
        justify-content: center;
        float:top;
        background:#ECEAE2
    }

    .mainpart p{
        text-align:center;
        font-size: 1em;
    }
    
    .mainpart h1{
        text-align:center;
        line-height: 1em;
        font-size: 1.3em;
    }    

    .header1{
        position: relative;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../assets/img1.jpg) no-repeat center ;
        background-size: cover;
        height: 18em;
        width: 100%;
    
    
        display: flex;              /* establish flex container */
    
        flex-direction: column;     /* stack flex items vertically */
        
        justify-content: center;    /* center items vertically, in this case */
        align-content: center;
        align-items: center;        /* center items horizontally, in this case */
    }

    .header2{
        position: relative;
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../assets/img2.jpeg) no-repeat center  ;
        background-size: cover;
        height: 13em;
        width: 100%;
    }
    

    
    .head-text1 p{
        position: inherit;
        width: 95%;
        color: #fff;
        font-weight: bolder;
        font-size: 2em;
        top: -5%;
        text-align: center;
        padding-left: 2.5%;
        line-height: 1.2em;

     }

     
    .head-text2 h1{
        position:inherit;
        color: #fff;
        font-weight: 500;
        margin-top: -0.5em;
        margin-bottom: 0.3em;
        font-size: 2.5rem;
        text-align: center;
    }

    .head-text2 p{ 
        color: #fff;
        margin-top: 0px;
        font-size: 1rem;
        text-align: center;
        text-emphasis-position: '03';
    }
}